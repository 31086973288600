<template>
  <div class="readme-article">
    <h1 id="专业院校配置">1.专业院校配置</h1>
    <h2 id="层次设置">1.1层次设置</h2>
    <p>院校层次设置，例如专科、本科等，后续的院校设置需要关联院校层次。</p>
    <p>
      <img src="@/assets/img/student/config1-1.png" />
    </p>
    <h2 id="院校设置">1.2院校设置</h2>
    <p>院校设置，包括院校、网教院校、成考院校、其他项目及项目分类设置。</p>
    <p>
      <img src="@/assets/img/student/config1-2.png" />
    </p>
    <h2 id="自考专业设置">1.3自考专业设置</h2>
    <p>
      院校专业设置，配置各个院校的自考专业，支持通过层次、省份筛选，管理员可对专业进行修改删除，开设班级。
    </p>
    <p>
      <img src="@/assets/img/student/config1-3.png" />
    </p>
    <h2 id="科目设置">1.4科目设置</h2>
    <p>科目设置，选择院校层次添加专业科目，例如高等数学，大学英语等。</p>
    <p>
      <img src="@/assets/img/student/config1-4.png" />
    </p>
    <h2 id="专业科目设置">1.5专业科目设置</h2>
    <p>专业科目关联设置，选择专业配置专业需学习科目。</p>
    <p>
      <img src="@/assets/img/student/config1-6.png" />
    </p>
    <h2 id="班型设置">1.6班型设置</h2>
    <p>
      班型设置，例如填写“普通”，“VIP”，“精品班”等，可以针对同一专业不同的班型设置不同价格。
    </p>
    <p>
      <img src="@/assets/img/student/config1-5.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Student0-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>